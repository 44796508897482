
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import BankModel from "@/components/modals/forms/settings/BankModel.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: "bank-info",
  components: {
    Datatable,
    BankModel,
  },
  data() {
    return {
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Bank Name",
          key: "bank_name",
          sortable: true,
        },
        {
          name: "Has Agent Banking",
          key: "has_agent_bank",
          sortable: true,
        },
        {
          name: "Agent Banking Prefix",
          key: "agent_bank_prefix",
          sortable: true,
        },
        {
          name: "Maximum value",
          key: "maximum_value",
          sortable: true,
        },
        {
          name: "Minimun Value",
          key: "minimum_value",
          sortable: true,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      componentKey: 0,
      data: {},
    };
  },
  async created() {
    await this.getBankInfo();
    Object.assign(this.tableData, this.lists);
    this.emitter.on("bank-info-updated", async () => {
      await this.getBankInfo();
      Object.assign(this.tableData, this.lists);
    });
  },
  methods: {
    async getBankInfo() {
      await ApiService.get("configurations/bank/list")
        .then((response) => {
          this.lists = response.data.data;
           this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    searchItems() {
      if (this.search !== "") {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit("bank-edit-modal-data", data);
    },

    add() {
      this.emitter.emit("bank-add-modal-data", true);
    },

    Delete(id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('configurations/bank/delete?id=' + id +
            '&responsible_user_id=' + user_id.id)
            .then((response) => {
              this.emitter.emit("bank-info-updated", true);
              Swal.fire("Deleted!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
