
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "add_bank",
  components: {},
  props: {
    data: { type: Object },
  },
  setup() {
    let user_id = VueCookieNext.getCookie("_seip_user");
    const formData = ref({
      id: "",
      bank_name: "",
      has_agent_bank: "",
      ab_prefix: "",
      ab_max_val: "",
      ab_min_val: "",
      user_id: user_id.id,
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      bank_name: [
        {
          required: true,
          message: "Bank name is required",
          trigger: "change",
        },
      ],
    });
    const { bus } = useBus();

    bus.on("bank-edit-modal-data", (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on("bank-add-modal-data", () => {
      update.value = false;
      let user_id = VueCookieNext.getCookie("_seip_user");
      formData.value = {
        id: "",
        bank_name: "",
        has_agent_bank: "",
        ab_prefix: "",
        ab_max_val: "",
        ab_min_val: "",
        user_id: user_id.id,
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const action = "post";
          const url = "configurations/bank/save";

          await ApiService[action](url, formData.value)
            .then((response) => {
              loading.value = false;
              bus.emit("bank-info-updated", true);
              if (response.status == 200) {
                Swal.fire({
                  text: response.data.data,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addModalRef.value);
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              loading.value = false;
              console.log(response);
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addModalRef,
    };
  },
});
